@import 'theme';

.fourohfour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $spacing--base;

  @include breakpoint(ipadPro) {
    margin: calc(#{$spacing--base}*6) 0;
  }

  h1 {
    font-size: 75px;
    @include breakpoint(ipad) {
      font-size: 150px;
      line-height: 1em;
    }
  }

  p {
    text-align: center;
  }

  .button {
    margin: $spacing--base auto;
  }
}
