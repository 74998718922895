// @changelog
// [0.0.1] - 6/25/19
// - Moved heading and button styles to _typography.scss
// - Lowered base-font-size to 18px per Jerry

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Colors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  $colors: (
    'primary--200': #6cb33f,
    'primary': #569b2a,
    'primary--400': #418019,
    'primary--600': #215301,
    'primary--700': #215301,
    'accent': #d17e47,
    'secondary': #6ba6d3,//#1894d2
    'foreground': #263640,
    'foreground--600': #17232b,
    'background': #fff,
    'background--400':#f0f0f0,
    'background--600': #a9bac7,

    'blue': #25399d,
    'orange': #f59700,
    'darkorange' : #cd7f00,
    'lightblue': #2f8ecf,
    'pink': #d03e8d,
    'red': #a6303b,
    'yellow': #e3dc20,
    'yellow--600' : #ffe300,
    'yellow--700' : #e0c800,
    'black': #000,

    'success': #469771,
    'warning': #f3ae57,
    'error': #f55e3e
  );

// ----------------------
  // Type
// ----------------------
  $rfs-base-font-size: 1rem;
  $global__fontFamily: 'Open Sans', 'system', sans-serif;

  // These should stay as pixels, RFS will handle responsive type.
  // https://www.npmjs.com/package/rfs
  $global__fontSize: 18px;

  // p weight
  $paragraph--weight:600;

  // Headings
  $global__weight--bold: 700;
  $global__weight--heavy: 800;
  $headings--weight:$global__weight--heavy;

  $headings__h1--fontSize: 36px; //was 40px
  $headings__h2--fontSize: 32px; //was 36px
  $headings__h3--fontSize: 28px; //was 32px
  $headings__h4--fontSize: 22px; //was 28px
  $headings__h5--fontSize: 20px; //was 25px

// ----------------------
  // a
// ----------------------
$link--color: color('primary');
$link--transition: all ease-out .25s;
$link-hover--color: color('primary',200);

// ----------------------
  // Button
// ----------------------
  $button--backgroundColor: color('yellow', 600);
  $button-hover--backgroundColor: color('yellow', 700);
  $button--textDecoration: none;
  $button--textTransform: none;
  $button--weight: $global__weight--heavy;
  $button--color: color('primary', 700);
  $button-hover--color: $button--color;
  $button__outline--color: $button--backgroundColor;

  $button--border: 1px solid color('yellow', 600);
  $button--borderRadius: 9px;
  $button--padding: .5em 3em .5em 2em;
  //$button--margin: 0 !default;
  $button--transition: all .25s ease-out;
  $button-hover--transition: all .25s ease-out;

  $link-hover--color: $button--color;


// ----------------------
// Lists
// ----------------------

$list--lineHeight: (
  1200: 0.85,
  640: 1.4
);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Settings
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // Spacing
  $spacing--base: 18px;

  // box-shadow
  @mixin box-shadow() {
    box-shadow: 0 3px 6px 0 transparentize(color('black'), .84);
  }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Breakpoints
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  @mixin breakpoint($point) {
    @if $point == desktop {
      @media only screen and (min-width: 1080.5px) {
        @content;
      }
    } @else if $point == ipadPro {
      @media only screen and (min-width: 768.5px) {
        @content;
      }
    } @else if $point == ipad {
      @media only screen and (min-width: 540.5px) {
        @content;
      }
    } @else if $point == mobile {
      @media only screen and (max-width: 540px) {
        @content;
      }
    }
  }
